<template>
  <div class="home affiliate">


    <template v-if="affiliate">
      <div class="row">
        <div class="col-4">
          <img width="200" src="https://sketch.media/images/connect/sketch_connect.svg"  >
        </div>
        <div class="col-4">
          &nbsp;
        </div>
        <div class="col-4">
          <a href="https://sketch.media" target="_blank">
            <img
              :src="logo"
              alt="logo"
              class="navbar-brand"
              style="width: 200px;margin-top: -24px;"
            /></a></div>
      </div>
      <hr style="background: #ddd; margin-bottom: 48px;">
      <div class="affiliateData">
        <h2 style="color: #fd7e14;">Deine Empfehlung lohnt sich!</h2>
        <p><strong>Dein Affiliate Key: </strong><br>{{affiliate.affiliate_hash}}</p>
        <p><strong>Deine Vereinbarung: </strong><br>{{affiliate.affiliate_percent}}% Provision auf
          <template v-if="affiliate.affiliate_type === 'full'">den Gesamtumsatz deiner Empfehlungen.</template>
          <template v-if="affiliate.affiliate_type === 'contract'">die Vertragsleistungen deiner Empfehlungen.</template>
        </p>
        <div><img src="https://sketch.media/images/connect/partnerprogramm/partner_herz_gif/animation_partner_herz.gif"   width="350" height="350" alt="partner programm" style="display: block; margin-left: auto; margin-right: auto;"></div>
<!--        <div class="later" style="display: none;">-->
<!--        <p>Deine Empfehlung hat bereits {{affiliate.affiliate_count}} Kunden gewonnen.</p>-->
<!--        <p>Deine Empfehlungen haben bereits {{affiliate.affiliate_revenue}} € Umsatz generiert.</p>-->
<!--        <p>Deine Empfehlungen haben bereits {{affiliate.affiliate_commission}} € Provision generiert.</p>-->
<!--        </div>-->
      </div>


    </template>
    <template v-else>

        <div class="row">
          <div class="col-4">
            <img width="200" src="https://sketch.media/images/connect/sketch_connect.svg"  >
          </div>
          <div class="col-4">
            &nbsp;
          </div>
          <div class="col-4">
            <a href="https://sketch.media" target="_blank">
              <img
                :src="logo"
                alt="logo"
                class="navbar-brand"
                style="width: 200px;margin-top: -24px;"
              /></a></div>
        </div>
      <hr style="background: #fd7e14; margin-bottom: 48px;">
      <div class="item-page">
        <h1 style="font-size: 2.5rem; text-align: center; color: #fd7e14;">Partner werden - Geld verdienen</h1>
        <div class="row">
          <div class="columns large-push-6 large-6 medium-12 small-12 wf-column">
            <p><img src="https://sketch.media/images/connect/partnerprogramm/provision_erhalten.svg"  width="309" height="283" alt="provision erhalten" style="display: block; margin-left: auto; margin-right: auto;"> </p>
          </div>
          <div class="columns large-pull-6 large-6 medium-12 small-12 wf-column">
            <h2 style="color: #f8aa6a;">Erhalte 15% Direktvergütung</h2>
            <p>Unser Partner-Programm bietet Dir attraktive Möglichkeiten, einfach und unkompliziert Einnahmen zu generieren. <br>Als Partner erhältst Du 15% Provision für jede erfolgreiche Empfehlung.</p>
            <p><router-link class="btn btn-primary btn-lg" style="font-size: 18px;padding: 10px 16px;" to="/affiliate/create">Hier kannst Du mitmachen!</router-link></p>
          </div>
        </div>
      <div class="row">
        <div class="columns large-6 medium-12 small-12">
          <img src="https://sketch.media/images/connect/partnerprogramm/netzwerk.svg"  width="311" height="231" alt="geld verdienen sparschein" style="display: block; margin-left: auto; margin-right: auto;">
        </div>
        <div class="columns large-6 medium-12 small-12">
          <h2 style="color: #f8aa6a;">Nutze Dein Netzwerk</h2>
          <p>Mach mit und verwandle Dein Netzwerk in eine Einnahmequelle – unverbindlich &amp; unkompliziert.<br>Kennst Du jemanden aus Deinem beruflichen Umfeld, Bekannten- oder Freundeskreis, der nach einem Verwaltungssystem (CRM), wie sketch.connect, sucht? <br>Dann mach es ganz einfach:</p>
        </div>
      </div>
      <div class="row">
        <div class="columns large-push-6 large-6 medium-12 small-12">
          <img src="https://sketch.media/images/connect/partnerprogramm/geld_verdienen_sparschein.svg"  width="255" height="249" alt="geld verdienen sparschein" style="display: block; margin-left: auto; margin-right: auto;">
        </div>
        <div class="columns large-pull-6 large-6 medium-12 small-12">
          <h2 style="color: #f8aa6a;">Empfehlen &amp; profitieren</h2>
          <p>Einfach registrieren, sketch.connect empfehlen und von Deiner erfolgreichen Vermittlung profitieren. Deine Empfehlung wird entlohnt - sowohl für <strong>reine Vertragsleistungen</strong> als auch <strong>einmalige Leistungen</strong>.</p>
        </div>
      </div>
      <div class="row">
        <div class="columns large-12 medium-12 small-12">
          <h2 style="color: #fd7e14; text-align: center;">Win-Win für alle Parteien</h2>
          <img src="https://sketch.media/images/connect/partnerprogramm/partner_herz_gif/animation_partner_herz.gif"   width="350" height="350" alt="partner programm" style="display: block; margin-left: auto; margin-right: auto;">
          <p style="text-align: center;font-size: 1rem;margin-top: 12px;margin-bottom: 48px;">Unsere Zusammenarbeit zielt darauf ab, dass jeder profitiert. <br>Gemeinsam formen wir eine Partnerschaft, in der<br><strong>Unternehmen</strong> ihre Verwaltung verbessern, <br><strong>Du</strong> lukrative Provisionen&nbsp;erzielst und <br><strong>Wir</strong> unseren Auftragsbestand &amp; unsere Reichweite erweitern.</p>
        </div>
      </div>
      <div class="row">
        <div class="columns large-12 medium-12 small-12">
          <div class="row">
          <div class="columns large-6 medium-12 small-12">

            <h2 style="color: #fd7e14;">So einfach funktioniert es</h2>
            <p><span class="h3" style="color: #f8aa6a;">1. Registrieren</span><br>Sende uns das ausgefüllte <strong><a href="#formular"><span style="color: #f8aa6a;">Formular</span></a></strong><br>So können wir den von Dir vermittelten Kunden klar zuordnen.</p>
            <p><span class="h3" style="color: #f8aa6a;">2. Kontaktaufnahme</span><br>Wir melden uns bei Dir und besprechen das weitere Vorgehen</p>
            <p><span class="h3" style="color: #f8aa6a;">3. Empfehlen</span><br>Du kennst Interessenten und empfiehlst unser CRM-System an potenzielle Kunden</p>
            <p><span class="h3" style="color: #f8aa6a;">4. Erfolgreiche Vermittlung</span><br>Durch Deine Empfehlung entsteht der Kontakt und im besten Fall ein Auftrag mit dem Interessenten. <br>Du musst Dich nicht um Formalitäten, Meetings oder Vertragsabschlüsse mit dem potenziellen Kunden kümmern. <br>Es genügt, wenn Du den Kontakt zwischen dem Interessenten und uns herstellst.</p>
            <p><span class="h3" style="color: #f8aa6a;">5. Nach Vertragsabschluss &amp; Zahlungseingang<br></span>Lehn Dich zurück und kassiere Deine wohlverdiente Provision - Wir machen den Rest!</p>
          </div>
          <div class="columns large-6 medium-12 small-12">
          <h3 style="color: #fd7e14;" class="h2">Deine Vorteile auf einen Blick</h3>
          <div class="row">
            <div class="columns large-12 medium-12 small-12">
              <span class="h3" style="color: #f8aa6a;">Attraktive Provisionen</span>
              <p >Verdiene <strong>15%</strong> Direktvergütung vom generierten Umsatz für jede erfolgreiche Empfehlung</p>
            </div>
            <div class="columns large-12 medium-12 small-12">
              <span class="h3" style="color: #f8aa6a;">Transparente Bedingungen</span>
              <p>Keine versteckten, laufenden Kosten oder Mindestlaufzeiten</p>
            </div>
            <div class="columns large-12 medium-12 small-12">
              <span class="h3" style="color: #f8aa6a;">Einfacher Einstieg&nbsp;</span>
              <p >Einfach anmelden und sofort loslegen – keine Knebelverträge, keine Hürden.</p>
            </div>
            <div class="columns large-12 medium-12 small-12">
              <span class="h3" style="color: #f8aa6a;">Flexibler Ablauf</span>
              <p >Du bist nicht durch Verträge und Verpflichtungen an die Interessenten gebunden.</p>
            </div>
            <div class="columns large-12 medium-12 small-12">
              <span class="h3" style="color: #f8aa6a;">Du hast nur einen Job</span>
              <p>Empfehle uns an Interessenten &amp; überlasse uns die Arbeit</p>
            </div>

          </div>
            </div></div>
          <h2 style="color: #fd7e14;">Wie schaut das in der Praxis aus?</h2>
          <h3 style="color: #6C757D;">Praxisbeispiel 1:&nbsp;</h3>
          <p><strong>Kleine Website mit CRM Basis Funktionen</strong></p>
          <p>Die Freundin Deines Bruders ist selbstständige Beraterin und benötigt eine kleine Website für 2.900€ mit einem Verwaltungs-System (CRM) für Ihre Unternehmensführung. <br>Sie nutzt die Standard-Funktionen von sketch.connect&nbsp;für 80€ (monatlich) bzw. 960€ (jährlich).&nbsp;<br>Zusätzlich wird ein Wartungsvertrag in Höhe von 35€ und Webhosting für 15€ monatlich abgeschlossen.&nbsp;</p>
          <p style="color: #fd7e14;">Du erhältst&nbsp;<strong><strong>435€ </strong></strong>für einmalige Leistungen&nbsp;<strong>+ 12€ </strong>monatlich bzw.<strong> 144€</strong> jährlich aus den Vertragsleistungen</p>
          <h3 style="color: #6C757D;">Praxisbeispiel 2:</h3>
          <p><strong>Große Unternehmensseite mit erweitertem CRM</strong></p>
          <p><img src="https://sketch.media/images/connect/partnerprogramm/provision_diagramm.svg"   width="460" height="282" alt="provision diagramm" style="float: right;"> </p>
          <p><span style="caret-color: auto;">Der Kumpel deines Geschäftspartners betreibt ein erfolgreiches Unternehmen. <br>Er möchte einen neuen Internetauftritt starten. Dazu gibt er eine individuelle Website mit einem integrierten CRM-System in Auftrag. <br>Zusätzlich zu den CRM-Basisfunktionen nutzt er maßgeschneiderte Erweiterungen für seine spezifischen Geschäftsprozesse. <br>Er wünscht sich eine laufende Wartung und Weiterentwicklung. <br>Für einmalige Leistungen fallen (Website, Einrichtung des CRMs) Kosten von 15.000€ an. Die Vertragsleistungen belaufen sich auf 2.000€ <span style="caret-color: auto;">jährlich</span>.</span></p>
          <p style="color: #fd7e14;">Du erhältst <strong>2.250€</strong> für einmalige Leistungen <strong>+ 300€</strong> jährlich für Vertragsleistungen</p>
          <p>Wenn Du Fragen hast, kannst du uns natürlich auch jederzeit <a href="https://sketch.media/connect/vorteile/#contactme" target="_blank"><span style="color: #f8aa6a;">kontaktieren</span></a>.</p>

          <p style="margin-bottom: 0;text-align: center;width: 100%;display: block;float: left; clear: both;"><img src="https://sketch.media/images/connect/pfeil_orange.gif"   width="95" height="143" alt="pfeil einfach" style="display: inline-block;"  ></p><p style="text-align: center;width: 100%;display: block;float: left; clear: both;"><router-link class="btn btn-primary btn-lg" style="font-size: 20px;padding: 20px 32px; margin-bottom: 42px;" to="/affiliate/create">Auf zur Partnerschaft!</router-link></p>
        </div>
      </div>
      </div>
      <AffiliateCreate v-if="$route.name === 'AffiliateCreate'" />
      </template>

  </div>

</template>
<script>
import axios from "axios";
import {mapGetters} from "vuex";
import AffiliateCreate from "@/routing/core/Affiliate/Create.vue";
export default {
  name: "Affiliate",

  data() {
    return {
      logo: require("@/assets/img/crm_pdf_logo_new.png"),
      affiliate: null,
    };
  },
  components: {AffiliateCreate},
  computed: {
    ...mapGetters(['getUser', 'getApiUrl'])
  },
  created() {

    if (this.getUser && this.getUser.crm_customer_id) {

      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.getApiUrl + "customer/" + this.getUser.crm_customer_id+"/affiliate" , {headers}).then((r) => {
        if (r.data.result.data) {
          this.affiliate = r.data.result.data;
        }
      });

    }
  },

};
</script>
<style scoped lang="scss">
.modal {
  z-index: 10000 !important;
}

.modal-backdrop {
  z-index: 9999 !important;
}
.btn-primary{
  background-color: #fd7e14;
  border-color: #fd7e14;
  &:hover{
    background-color: #f8aa6a;
    border-color: #f8aa6a;
  }
}



</style>
